import { defineMessages } from 'react-intl';

export const scope = 'barcelona.templates.BlogAuthor';

export default defineMessages({
  pageTitle: {
    id: `${scope}.pageTitle`,
    defaultMessage: 'Author: {author}{pageNumber, plural, =0 {} other {, page {pageNumber}}}'
  },
  seoTitle: {
    id: `${scope}.seoTitle`,
    defaultMessage: 'Author: {author} - from the blog{pageNumber, plural, =0 {} other {, page {pageNumber}}}'
  },
  seoMetaDescription: {
    id: `${scope}.seoMetaDescription`,
    defaultMessage: 'We are proud to present our travel blog articles written by {author}{pageNumber, plural, =0 {} other {, page {pageNumber}}}, packed with travel guides and useful information for your next holiday'
  }
});
