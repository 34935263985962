import React from 'react';
import { graphql } from 'gatsby';
import Blog from '@rentivo/gatsby-theme-barcelona/src/templates/Blog/component';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-theme-barcelona/src/templates/Author/messages';

const BlogAuthor = (props) => {
  const { formatMessage } = useIntl();
  const { pageContext: { name, pageNumber }} = props;

  const values = {
    author: name,
    pageNumber
  };

  const seo = {
    seoTitle: formatMessage(messages.seoTitle, values),
    seoMetaDescription: formatMessage(messages.seoMetaDescription, values)
  };

  return <Blog {...props} showFeatured={false} seo={seo} title={<FormattedMessage {...messages.pageTitle} values={values}/>}/>;
};

export default BlogAuthor;

export const pageQuery = graphql`
  query BlogAuthorQuery($ids: [String]) {
    allWpPost(limit: 100, filter: {id: {in: $ids}}) {
      nodes {
        ...PostListFields
        translations {
          ...PostListFields
        }
      }
    }
  }
`;
